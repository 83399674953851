html { height: 100%; }
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  font-family: 'ABeeZee';
  background: #000;
}

#root {  height: 100%; }

h1,h2,h3,h4,h5 {
  font-family: 'Akaya Telivigala';
}

:root {
  --ruby: #e60c0c;
  --emerald: #5ee465;
  --diamond: #f0f0f0;
  --sapphire: #2a55e6;
  --onyx: #222222;
  --star: #ffdb63;

  --gold: #e4b92d;
  --dark-gold: #a3841d;

  --confirm: #14dd50;
  --confirm-hover: #22fc63;
  --cancel: #bd0000;
  --cancel-hover: #f30000;
}
